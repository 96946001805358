import { MutationParams } from '@/types/api/common';
import {
  IGetCustomerPaymentMethodsReqParams,
  IGetCustomerPaymentMethodsRes,
  IGetCustomerRes,
  IPostCustomerSearchReqBody,
  IPostCustomerSearchRes,
  IPostCustomersReqBody,
  IPostCustomersRes,
} from '@/types/api/customer';
import {
  IPostPaymentIntentReqBody,
  IUpdatePaymentIntentReqParams,
} from '@/types/api/payment-intent';
import {
  IPostPaymentMethodParams,
  IPostPaymentMethodReqBody,
} from '@/types/api/payment-method';
import {
  IPostSetupIntentsReqBody,
  IPostSetupIntentsRes,
} from '@/types/api/setup-intent';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const HANDLER_QUERY_TAG = {
  paymentMethods: 'payment-methods',
  customer: 'customer',
};

const localApis = createApi({
  reducerPath: 'handler',
  baseQuery: fetchBaseQuery({
    baseUrl: '/handler',
    headers: {
      'Content-Type': 'application/json',
    },
  }),
  tagTypes: [...Object.values(HANDLER_QUERY_TAG)],
  endpoints: (build) => ({
    updatePaymentIntent: build.mutation<
      unknown,
      MutationParams<IPostPaymentIntentReqBody, IUpdatePaymentIntentReqParams>
    >({
      query: ({ params, body }) => ({
        url: `/payment-intents/${params.paymentIntentId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
    }),

    createSetupIntent: build.mutation<
      IPostSetupIntentsRes,
      IPostSetupIntentsReqBody
    >({
      query: (body) => ({
        url: `/setup-intents`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
    }),

    getCustomerPaymentMethods: build.query<
      IGetCustomerPaymentMethodsRes,
      IGetCustomerPaymentMethodsReqParams
    >({
      query: ({ customerId }) => ({
        url: `/customers/${customerId}/payment-methods`,
        method: 'GET',
      }),
      providesTags: [HANDLER_QUERY_TAG.paymentMethods],
    }),

    updatePaymentMethod: build.mutation<
      unknown,
      MutationParams<IPostPaymentMethodReqBody, IPostPaymentMethodParams>
    >({
      query: ({ body, params: { paymentMethodId } }) => ({
        url: `/payment-methods/${paymentMethodId}`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [HANDLER_QUERY_TAG.paymentMethods],
    }),

    searchCustomer: build.mutation<
      IPostCustomerSearchRes,
      MutationParams<IPostCustomerSearchReqBody, {}>
    >({
      query: ({ body }) => ({
        url: `/customers/search`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        HANDLER_QUERY_TAG.paymentMethods,
        HANDLER_QUERY_TAG.customer,
      ],
    }),

    getCustomer: build.query<
      IGetCustomerRes,
      IGetCustomerPaymentMethodsReqParams
    >({
      query: ({ customerId }) => ({
        url: `/customers/${customerId}`,
        method: 'GET',
      }),
      providesTags: [HANDLER_QUERY_TAG.customer],
    }),

    createCustomer: build.mutation<
      IPostCustomersRes,
      MutationParams<IPostCustomersReqBody, {}>
    >({
      query: ({ body }) => ({
        url: `/customers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: [],
    }),
  }),
});

export const {
  useUpdatePaymentIntentMutation,
  useGetCustomerPaymentMethodsQuery,
  useUpdatePaymentMethodMutation,
  useSearchCustomerMutation,
  useGetCustomerQuery,
  useCreateCustomerMutation,
  useCreateSetupIntentMutation
} = localApis;

export default localApis;
