import {
  configureStore,
  combineReducers,
  AnyAction,
  Reducer,
} from '@reduxjs/toolkit';
import { userReducer } from '@/store/slices/user';
import { appReducer } from '@/store/slices/app';
import localApis from '@/store/apis/handler';
import appApis from '@/store/apis/app';
import wiseApis from '@/store/apis/wise';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const reducer = combineReducers({
  user: userReducer,
  app: appReducer,
  [localApis.reducerPath]: localApis.reducer,
  [wiseApis.reducerPath]: wiseApis.reducer,
  [appApis.reducerPath]: appApis.reducer,
});

const persistConfig: PersistConfig<any> = {
  key: 'root',
  storage,
  blacklist: [appApis.reducerPath],
  timeout: 10,
};

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'app/clearPersistState') {
    storage.removeItem('persist:root');

    state = {} as RootState;
  }
  return reducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const makeStore = () =>
  configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }).concat([
        // TODO: place API reducers here
        localApis.middleware,
        wiseApis.middleware,
        appApis.middleware,
      ]),
    devTools: process.env.NODE_ENV !== 'production',
  });

export const store = makeStore();

export type RootState = ReturnType<typeof reducer>;
export type AppStore = ReturnType<typeof makeStore>;
