'use client';

import { AppStore, store } from '@/store';
import { setupListeners } from '@reduxjs/toolkit/query';
import { FC, Suspense, useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate, PersistGateProps } from 'redux-persist/integration/react';

interface ReduxProvidersProps {
  children: React.ReactNode;
  persistGateProps?: Partial<PersistGateProps>;
}
const ReduxProviders: FC<ReduxProvidersProps> = ({
  children,
  persistGateProps,
}) => {
  const storeRef = useRef<AppStore | null>(null);

  if (!storeRef.current) {
    // Create the store instance the first time this renders
    storeRef.current = store;
  }

  useEffect(() => {
    if (storeRef.current != null) {
      // configure listeners using the provided defaults
      // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
      const unsubscribe = setupListeners(storeRef.current.dispatch);
      return unsubscribe;
    }
  }, []);

  return (
    <Suspense>
      <Provider store={storeRef.current}>
        <PersistGate
          persistor={persistStore(storeRef.current)}
          {...persistGateProps}
        >
          {children}
        </PersistGate>
      </Provider>
    </Suspense>
  );
};

export default ReduxProviders;
