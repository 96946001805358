import { Metal } from '@/types/api/app/product';
import { IMetal } from '@/types/metal';
import { IProductCurrency } from '@/types/product';

export const COST_RATE_PER_METAL_GRAM: { [key in IMetal]: number } = {
  [IMetal.gold14k]: 60,
  [IMetal.gold18k]: 60,
  [IMetal.gold24k]: 60,
  // TODO: add platinum pricing
};

export const PLATFORM_FEE_RATE = 0.75;
export const DESIGN_FEE = {
  [IProductCurrency.usd]: 11.9,
};

export const VALUE_RANGE_MIN = 0;
export const VALUE_RANGE_MAX = 10_000;
export const ANY_VALUE_RANGE = [VALUE_RANGE_MIN, VALUE_RANGE_MAX];
export const PRODUCT_VARIANT_REFRESH_INTERVAL = 15 * 60 * 1_000;

export const GOLD_METALS = [
  Metal.WhiteGold18K,
  Metal.YellowGold18K,
  Metal.YellowGold24K,
];
