import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '@/store';
import { useSelector } from 'react-redux';

export interface UserState {
  isLoggedIn: boolean;
  isReturningUser: boolean;
}

const initialState: UserState = {
  isLoggedIn: false,
  isReturningUser: true,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<UserState['isLoggedIn']>) => {
      state.isLoggedIn = action.payload;
    },
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
export const useIsLoggedInSelector = () =>
  useSelector((state: RootState) => state.user.isLoggedIn);
export const useIsReturningUserSelector = () =>
  useSelector((state: RootState) => state.user.isReturningUser);
