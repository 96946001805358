import { NEXT_PUBLIC_WISE_API_KEY } from '@/config/wise';
import { QueryParams } from '@/types/api/common';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const wiseApis = createApi({
  reducerPath: 'wise',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.transferwise.com/v1',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${NEXT_PUBLIC_WISE_API_KEY}`,
    },
  }),
  tagTypes: [],
  endpoints: (build) => ({
    getCurrencyRate: build.query<
      { rate: number; source: string; target: string; time: string }[],
      QueryParams<{ source: string; target: string }, {}>
    >({
      query: ({ search }) => ({
        url: `/rates?${new URLSearchParams(search).toString()}`,
        method: 'GET',
      }),
      providesTags: [],
    }),
  }),
});

export const { useGetCurrencyRateQuery } = wiseApis;

export default wiseApis;
