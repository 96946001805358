import { COST_RATE_PER_METAL_GRAM, DESIGN_FEE } from '@/constants/product';
import colors from '@/constants/theme/colors';
import { IAppProductVariant, Metal } from '@/types/api/app/product';
import { ICartItem } from '@/types/cart';
import { IMetal } from '@/types/metal';
import {
  ICartProductItem,
  IProductCurrency,
  IProductItem,
  IProductMetalType,
} from '@/types/product';

export const checkIfPCartProductItemMatch =
  (cartProductItem: ICartProductItem) => (item: ICartItem) =>
    item.product.id === cartProductItem.product.id &&
    item.productVariant.id === cartProductItem.productVariant.id;

export const calculateProductBasePrice: (params: {
  metalWeight: number;
  metal: IMetal;
  currency: IProductCurrency;
}) => number = ({ metalWeight, metal, currency }) => {
  if (!metal || !metal) return 0;

  const price =
    metalWeight * COST_RATE_PER_METAL_GRAM[metal] +
    metalWeight * DESIGN_FEE[currency];
  // TODO: handle currency
  return Math.round(price * 100);
};

export const mapAppProductVariantToProductItem: (
  productVariant: IAppProductVariant
) => IProductItem = (productVariant) => {
  const {
    product,
    id: productVariantId,
    metal,
    price,
    priceCurrency,
    size,
  } = productVariant;
  const { id: productId, slug, title } = product;
  const { photoOrVideoUrls } = productVariant;

  return {
    name: !!size ? `${title} - ${size}` : title,
    productId,
    productVariantId,
    metalType: getProductMetalType(metal),
    thumbnailUrl: photoOrVideoUrls?.[0] ?? '',
    priceValue: price,
    priceCurrency,
    slug,
    product,
    productVariant,
  };
};

export const formatPrice =
  (currency: string) =>
  (value: number, currencyUnitRatio: number = 100) => {
    const price = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(value / currencyUnitRatio);

    // remove currency symbol
    return price;
  };

export const getCurrencySymbol = (currency: string) => {
  const price = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(1);

  // remove currency symbol
  return price.replace('1', '').trim();
};

export const getProductMetalType: (metal: string) => IProductMetalType = (
  metal
) => {
  return metal?.includes('gold')
    ? IProductMetalType.gold
    : IProductMetalType.platinum;
};

export const displayProductVariantMetal = (
  metal: IAppProductVariant['metal']
) => {
  const primaryMetal = getPrimaryMetal(metal)!;
  const secondaryMetal = getSecondaryMetal(metal);

  const { value: priValue, color } = displayMetal(primaryMetal);

  return {
    value: !secondaryMetal
      ? priValue
      : `${priValue} + ${displayMetal(secondaryMetal).value}`,
    color,
  };
};

const displayMetal = (metal: Metal) => {
  switch (metal) {
    case Metal.YellowGold18K:
      return { value: '18K Gold', color: colors.secondary[100] };

    case Metal.YellowGold24K:
      return { value: '24K Gold', color: colors.secondary[100] };

    case Metal.WhiteGold18K:
      return { value: '18K White Gold', color: colors.grey[500] };

    case Metal.Platinum:
      return { value: 'Platinum', color: colors.grey[500] };

    default:
      return { value: '', color: '' };
  }
};

export const getMetalOptionsFromProductVariants: (
  productVariants: IAppProductVariant[]
) => IProductMetalType[] = (productVariants) => {
  if (!productVariants) return [];
  let options = [];
  const hasGold = productVariants.find((variant) =>
    variant.metal.includes('gold')
  );
  const hasPlatinum = productVariants.find((variant) =>
    variant.metal.includes('platinum')
  );
  if (hasGold) options.push(IProductMetalType.gold);
  if (hasPlatinum) options.push(IProductMetalType.platinum);
  return options;
};

export const trimCountryName = (name: string) => {
  if (name.length < 15) return name;
  return `${name.substring(0, 13)}...`;
};

export const getPrimaryMetal = (metal: IAppProductVariant['metal']) => {
  if (metal.includes(':')) return metal?.split(':')[0] as Metal;

  return metal as Metal;
};

export const getSecondaryMetal = (metal: IAppProductVariant['metal']) => {
  return metal?.split(':')[1] as Metal | undefined;
};
